// @font-face {
//     font-family: 'Satoshi';
//     src: url('../assets/fonts/Satoshi-Variable.woff2') format('woff2'),
//          url('../assets/fonts/Satoshi-Variable.woff') format('woff'),
//          url('../assets/fonts/Satoshi-Variable.ttf') format('truetype');
//          font-weight: 300 900;
//          font-display: swap;
//          font-style: normal;
//   }

/**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 900.0;
  *
  * available axes:
  
  * 'wght' (range from 300.0 to 900.0)
  
  */

//   @font-face {
//     font-family: 'Satoshi-VariableItalic';
//     src: url('../assets/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
//          url('../assets/fonts/Satoshi-VariableItalic.woff') format('woff'),
//          url('../assets/fonts/Satoshi-VariableItalic.ttf') format('truetype');
//          font-weight: 300 900;
//          font-display: swap;
//          font-style: italic;
//   }

@font-face {
  font-family: "Aeonik";
  src: url("../assets/fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../assets/fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../assets/fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../assets/fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../assets/fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
