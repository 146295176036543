.rate {
  //display: grid;
  // grid-template-columns: 1fr 1fr 150px;
  // grid-template-columns: 1fr 1fr 1fr;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // justify-content: space-between;
  margin-top: 1rem;
  @media (max-width: 750px) {
    border-bottom: 1px solid #e8e7e7;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (max-width: 500px) {
    margin-top: 1rem;
    padding-bottom: 1rem;
    // grid-template-columns: 120px 1fr 60px;
  }
  @media (max-width: 420px) {
    // grid-template-columns: 100px 1fr 60px;
  }
  @media (max-width: 340px) {
    // grid-template-columns: 80px 1fr 60px;
  }
  ///background: pink;

  &__platform {
    //background: teal;
    width: 200px;
    margin-top: 0rem;

    @media (max-width: 750px) {
      width: 120px;
    }
    @media (max-width: 420px) {
      width: 100px;
    }
    @media (max-width: 340px) {
      width: 80px;
    }
  }

  &__rate {
    //background: teal;
    width: 200px;
    //margin-left: auto;
    @media (max-width: 500px) {
      // width: fit-content;
      //margin-left: auto;
    }
    @media (max-width: 750px) {
      width: 120px;
    }
    @media (max-width: 420px) {
      width: 100px;
    }
    @media (max-width: 340px) {
      width: 80px;
    }
    &__main {
      width: 100px;
      margin-left: auto;
      @media (max-width: 500px) {
        width: 60px;
      }
    }
    h4 {
      color: #1e1e1e;
      font-family: Aeonik;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 500px) {
        font-size: 1rem;
        text-align: right;
      }
    }
    h6 {
      color: #0ec120;
      font-family: Aeonik;
      font-size: 0.7125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.3rem;
      @media (max-width: 500px) {
        font-size: 0.6rem;
        text-align: right;
      }
    }
  }

  &__currencyPair {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    padding: 0 0.5rem;
    //background: pink;
    @media (max-width: 500px) {
      //   display: block;
      padding: 0 0.2rem;
      margin: auto;
    }

    &__arrow {
      margin: 0 1rem;
      margin-bottom: 1rem;
      width: 25px;
      @media (max-width: 750px) {
        width: 20px;
        margin: 0 0.1rem;
        margin-bottom: 0.7rem;
      }
      @media (max-width: 500px) {
        width: 18px;
        margin-bottom: 0.4rem;
      }
    }

    &__from,
    &__to {
      h6 {
        margin-bottom: 1rem;
        color: #a5a1a1;
        font-family: Aeonik;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
