.platform {
  width: 180px;
  height: 80px;
  border-radius: 1.5rem;
  border: 1px solid var(--neutral-colors-400, #d6dce5);
  box-shadow: 0px 2px 20px 0px rgba(183, 183, 183, 0.08),
    0px 20px 40px 0px rgba(183, 183, 183, 0.08);
  position: relative;
  display: grid;
  place-content: center;
  @media (max-width: 850px) {
    width: 150px;
  }
  @media (max-width: 750px) {
    width: 100px;
    height: 50px;
  }
  @media (max-width: 450px) {
    width: 70px;
    border-radius: 1rem;
  }

  img {
    height: 50px;
    max-width: 120px;
    object-fit: contain;
    @media (max-width: 750px) {
      max-width: 80px;
      height: 33px;
    }
    @media (max-width: 450px) {
      max-width: 60px;
      height: 33px;
    }
  }

  &__tag {
    position: absolute;
    //background: #f0b90b;
    border-radius: 20px;
    padding: 0.2rem 0.8rem;
    right: -10px;
    top: -9px;
    @media (max-width: 750px) {
      padding: 0.2rem 0.5rem;
    }
    p {
      color: #fff;
      text-align: center;
      font-family: Aeonik;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 750px) {
        font-size: 0.5rem;
      }
    }
  }
}
