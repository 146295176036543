.convert {
  //   h1 {
  //     font-family: Aeonik;
  //     font-weight: 300;
  //     font-size: 2rem;
  //   }

  &__intro {
    padding: 2rem 4rem;
    @media (max-width: 750px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }
    &__heading {
      color: #262cbe;
      text-align: center;
      font-family: Aeonik;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 900px;
      margin: auto;
      @media (max-width: 850px) {
        font-size: 3.4rem;
      }
      @media (max-width: 750px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2.5rem;
      }
      @media (max-width: 500px) {
        font-size: 2rem;
      }
      @media (max-width: 400px) {
        font-size: 1.7rem;
      }
    }

    &__nations {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      @media (max-width: 500px) {
        margin-top: 0.5rem;
      }

      img {
        margin: 0 0.4rem;
        width: 40px;
        @media (max-width: 750px) {
          width: 30px;
          margin: 0 0.2rem;
        }
        @media (max-width: 400px) {
          width: 25px;
          margin: 0 0.1rem;
        }
      }

      &__more {
        border-radius: 2.5rem;
        background: rgba(217, 217, 217, 0.26);
        padding: 0.5rem 1rem;
        margin-left: 0.2rem;
        @media (max-width: 400px) {
          padding: 0.3rem 0.7rem;
        }
        p {
          font-size: 0.875rem;
          color: #000;
          @media (max-width: 400px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    &__desc {
      text-align: center;
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 900px;
      margin: auto;
      @media (max-width: 750px) {
        font-size: 1rem;
        max-width: 600px;
      }

      @media (max-width: 400px) {
        font-size: 0.9rem;
      }
    }
  }

  &__rate {
    width: 100%;
    max-width: 900px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 2rem;

    padding: 0rem 4rem;
    @media (max-width: 750px) {
      padding: 0rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 0rem 1rem;
    }

    @media (max-width: 600px) {
      display: block;
      margin-top: 1rem;
    }
    &__from,
    &__to {
      @media (max-width: 600px) {
        display: flex;
        align-items: center;
        margin: auto;
        width: fit-content;
      }
      h6 {
        color: #a5a1a1;
        font-family: Aeonik;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1rem;
        @media (max-width: 600px) {
          margin-bottom: 0rem;
          font-size: 0.8rem;
          margin-right: 1rem;
        }
      }
    }

    &__arrow {
      margin: 0 3rem;
      margin-bottom: 0.9rem;
      cursor: pointer;
      // width: fit-content;
      // @media(max-width: 750px){
      //   margin: 0 1rem;
      // }

      h6 {
        text-align: center;
        color: #a5a1a1;
        text-align: center;
        font-family: Aeonik;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.5rem;
        // padding: 2rem 4rem;
        @media (max-width: 600px) {
          display: none;
        }
      }

      img {
        width: 17px;
        display: block;
        margin: auto;
      }

      @media (max-width: 600px) {
        margin: 1rem 0;
        transform: rotate(90deg);
      }
    }

    &__to {
      &__box {
        display: flex;
        height: 60px;
        max-width: 300px;
        border-radius: 1.0625rem;
        background: rgba(38, 44, 190, 0.05);
        @media (max-width: 600px) {
          height: 50px;
          border-radius: 0.8rem;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        input {
          width: 150px;
          border: none;
          outline: none;
          font-size: 1.1rem;
          padding-right: 1rem;
          padding-left: 0.5rem;
          background: transparent;
          @media (max-width: 600px) {
            width: 100px;
          }
        }
        &__line {
          width: 2px;
          height: calc(100% - 40px);
          margin-top: 20px;
          background: #3e434791;
          @media (max-width: 600px) {
            height: calc(100% - 30px);
            margin-top: 15px;
          }
        }
      }
    }
  }

  &__result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    margin-top: 2rem;

    @media (max-width: 750px) {
      padding: 0rem 2rem;
      margin-top: 3rem;
      display: block;
    }
    @media (max-width: 600px) {
      padding: 0rem 1rem;
    }
    &__value {
      max-width: 300px;

      h3 {
        color: #262cbe;
        font-family: Aeonik;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        color: #3e4347;
        font-family: Aeonik;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &__notify {
      display: flex;
      align-items: center;
      border-radius: 3.3125rem;
      border: 1px solid #dadada;
      padding: 0.5rem 1rem;
      width: fit-content;
      cursor: pointer;
      @media (max-width: 750px) {
        margin-top: 1rem;
      }

      img {
        width: 20px;
      }

      p {
        color: #1e1e1e;
        font-family: Aeonik;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.2rem;
      }
    }
  }

  &__rates {
    padding: 0 2rem;
    @media (max-width: 600px) {
      padding: 0 1rem;
    }
  }
  &__disclaimer {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 2rem;
    padding-top: 4rem;

    p {
      font-size: 1rem;
      text-align: justify;
      color: #808080;
      font-family: Aeonik;
      font-size: 0.95rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0rem;
      @media (max-width: 1032px) {
        margin: 0 2rem;
      }
      @media (max-width: 600px) {
        margin: 0 1rem;
        font-size: 0.8rem;
      }
    }
  }
}
