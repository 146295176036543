.modalWrapper {
  background: rgba(217, 217, 217, 0.45);
  width: 100%;
  margin: auto;
  height: 100vh;
  position: fixed;
  z-index: 8;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  top: 0;
  left: 0;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__main {
    width: 100%;
    max-width: 700px;
    padding: 0 1rem;
    height: auto;
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
  &__body {
    width: 100%;
    animation: modalWrapper 0.2s ease-out 1;
    @keyframes modalWrapper {
      from {
        transform: scale(0.9);
      }
      to {
        transform: scale(1);
      }
    }
  }
}
