.getNotified {
  background: pink;
  border-radius: 2.75rem;
  background: #fff;
  box-shadow: 0px 6px 91px 30px rgba(0, 0, 0, 0.12);
  min-height: 200px;
  padding-top: 2rem;
  @media (max-width: 500px) {
    padding-top: 1rem;
    border-radius: 1.5rem;
  }
  //   padding-right: 2rem;

  &__cancel {
    background: rgba(38, 44, 190, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-left: auto;
    margin-right: 2rem;
    @media (max-width: 500px) {
      margin-right: 1rem;
      width: 30px;
      height: 30px;
    }

    img {
      width: 20px;
    }
  }

  &__main {
    padding: 2rem 4rem;
    padding-bottom: 4rem;
    @media (max-width: 600px) {
      padding: 2rem 2rem;
      padding-bottom: 4rem;
    }
    @media (max-width: 500px) {
      padding: 2rem 1rem;
      padding-bottom: 2rem;
      padding-top: 1rem;
    }

    &__heading {
      color: #262cbe;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 500px) {
        font-size: 1.5rem;
        text-align: left;
      }
    }

    &__desc {
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.5rem;
      @media (max-width: 500px) {
        font-size: 0.9rem;
        text-align: left;
      }
    }

    &__options {
      margin-top: 3rem;
      @media (max-width: 500px) {
        margin-top: 1rem;
      }
    }

    &__option {
      display: grid;
      grid-template-columns: 1fr 100px;
      margin-top: 1rem;
      align-items: center;
      h4 {
        color: #1e1e1e;
        font-family: Aeonik;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }
      p {
        color: #1e1e1e;
        font-family: Aeonik;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.5rem;
        @media (max-width: 500px) {
          font-size: 0.9rem;
        }
      }

      &__checkbox {
        width: 30px;
        height: 30px;
        border-radius: 1.375rem;
        border: 2px solid #979797;
        cursor: pointer;
        margin-left: auto;
        position: relative;
        @media (max-width: 500px) {
          width: 24px;
          height: 24px;
        }

        &__checked {
          &:after {
            position: absolute;
            width: 21px;
            height: 21px;
            content: "";
            //background: #262cbe;
            background: #535bf0;
            border-radius: 50%;
            //   top: 3px;
            //   left: 3px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 500px) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &__rate {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 2rem;

      @media (max-width: 500px) {
        display: block;
        margin-top: 1rem;
      }
      &__from,
      &__to {
        h6 {
          color: #a5a1a1;
          font-family: Aeonik;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 1rem;
          @media (max-width: 500px) {
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
          }
        }
      }

      &__arrow {
        margin-bottom: 0.9rem;
        @media (max-width: 500px) {
          margin: 0.5rem 0;
          margin-top: 0.7rem;
          transform: rotate(90deg);
        }
      }

      &__to {
        &__box {
          display: flex;
          height: 50px;
          max-width: 220px;
          border-radius: 1.0625rem;
          background: rgba(38, 44, 190, 0.05);
          @media (max-width: 600px) {
            height: 40px;
            border-radius: 0.8rem;
            max-width: 200px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
          input {
            width: 100px;
            border: none;
            outline: none;
            font-size: 1.1rem;
            padding-right: 1rem;
            padding-left: 0.5rem;
            background: transparent;
          }
          &__line {
            width: 2px;
            height: calc(100% - 30px);
            margin-top: 15px;
            background: #3e4347bc;
            @media (max-width: 600px) {
              height: calc(100% - 20px);
              margin-top: 10px;
            }
          }
        }
      }
    }

    &__email {
      margin-top: 2rem;

      h3 {
        color: #1e1e1e;
        font-family: Aeonik;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }
      input {
        margin-top: 1rem;
        border-radius: 1.0625rem;
        background: rgba(38, 44, 190, 0.05);
        padding: 0 1rem;
        height: 60px;
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.1rem;
        @media (max-width: 500px) {
          margin-top: 0.5rem;
          font-size: 1rem;
          height: 50px;
          border-radius: 0.8rem;
        }
        &::placeholder {
          color: #979797;
          font-family: Aeonik;
          font-size: 1rem;
          font-weight: 400;
          @media (max-width: 500px) {
            font-size: 0.9rem;
          }
        }
      }
    }

    &__submit {
      border-radius: 3.3125rem;
      background: #262cbe;
      height: 60px;
      font-size: 1.1rem;
      color: #fff;
      width: 100%;
      display: block;
      margin: auto;
      margin-top: 3rem;
      cursor: pointer;
      font-family: Aeonik;
      font-weight: 400;
      display: grid;
      place-content: center;
      @media (max-width: 500px) {
        font-size: 1rem;
        margin-top: 2rem;
        height: 50px;
      }
    }
  }
}
