.navBar {
  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
    height: 80px;

    @media (max-width: 1100px) {
      padding: 0rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 0rem 1rem;
      height: 70px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 40px;
      @media (max-width: 600px) {
        width: 30px;
      }
    }
    h2 {
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 0.5rem;
      b {
        color: #262cbe;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }

  &__menu {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: rgba(38, 44, 190, 0.07);
    display: grid;
    place-content: center;
    display: none;
    @media (max-width: 600px) {
      width: 35px;
      height: 35px;
    }
    img {
      width: 22px;
      @media (max-width: 600px) {
        width: 15px;
      }
    }
  }

  &__actions {
    background: rgba(38, 44, 190, 0.1);
    overflow-x: auto;

    &__content {
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0;
      width: fit-content;
      margin: auto;

      @media (max-width: 750px) {
        // max-width: 500px;
        margin: auto;
        padding: 0 2rem;
      }
      @media (max-width: 500px) {
        //max-width: 400px;
        margin: auto;
      
      }
      @media (max-width: 600px) {
        min-height: 60px;
        padding: 0 1rem;
      }
    }
  }

  &__action {
    margin: 0 2rem;
    height: calc(70px - 1rem);
    width: fit-content;
    display: grid;
    place-content: center;
    padding: 0 1.5rem;
    border-radius: 20rem;
    cursor: pointer;
    @media (max-width: 750px) {
      padding: 0rem 1rem;
      margin: 0 1rem;
    }
    @media (max-width: 600px) {
      height: calc(60px - 1rem);
    }
    @media (max-width: 500px) {
      margin: 0 0.2rem;
    }
    @media (max-width: 500px) {
      padding: 0rem 0.8rem;
    }

    h3 {
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
      @media (max-width: 750px) {
        font-size: 0.9rem;
      }
      @media (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    &__active {
      background: #ffff;
      h3 {
        color: #262cbe;
      }
    }
  }
}
