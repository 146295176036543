.success {
  background: pink;
  border-radius: 2.75rem;
  background: #fff;
  box-shadow: 0px 6px 91px 30px rgba(0, 0, 0, 0.12);
  min-height: 200px;
  padding-top: 2rem;
  @media (max-width: 500px) {
    padding-top: 1rem;
    border-radius: 1.5rem;
  }
  //   padding-right: 2rem;

  &__cancel {
    background: rgba(38, 44, 190, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-left: auto;
    margin-right: 2rem;
    cursor: pointer;
    @media (max-width: 500px) {
      margin-right: 1rem;
      width: 30px;
      height: 30px;
    }

    img {
      width: 20px;
    }
  }

  &__main {
    padding: 2rem 4rem;
    padding-bottom: 4rem;
    @media (max-width: 600px) {
      padding: 2rem 2rem;
      padding-bottom: 4rem;
    }
    @media (max-width: 500px) {
      padding: 2rem 1rem;
      padding-bottom: 2rem;
      padding-top: 1rem;
    }
    &__icon {
      width: 90px;
      height: 90px;
      margin: auto;
      background: rgba(38, 44, 190, 0.05);
      display: grid;
      place-content: center;
      border-radius: 50%;
      @media (max-width: 600px) {
        width: 70px;
        height: 70px;
      }
      img {
        width: 60px;
        height: 60px;
        @media (max-width: 600px) {
          width: 50px;
          height: 50px;
        }
      }
    }
    &__heading {
      color: #262cbe;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 500px) {
        font-size: 1.5rem;
      }
      @media (max-width: 400px) {
       max-width: 200px;
       margin: auto;
      }

    }

    &__desc {
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.5rem;
      @media (max-width: 500px) {
        font-size: 0.9rem;
      }
    }
    &__buttons {
      max-width: 500px;
      margin: 0 auto;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    &__submit {
      border-radius: 3.3125rem;
      background: #262cbe;
      height: 60px;
      font-size: 1.1rem;
      color: #fff;
      width: 100%;
      display: block;
      margin: auto;
      margin-top: 1rem;
      cursor: pointer;
      font-family: Aeonik;
      font-weight: 400;
      border: 2px solid #262cbe;
      // &:last-child {
      //   background: #fff;
      //   color: #262cbe;
      // }
      @media (max-width: 500px) {
        font-size: 1rem;
        height: 50px;
      }
    }
  }
}
