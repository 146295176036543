.conversions {
  max-width: 1000px;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 5rem;
  &__heading {
    border-radius: 0.8rem;
    background: #262cbe;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    color: #fff;
    padding: 0 4rem;
    @media (max-width: 750px) {
      height: 60px;
      padding: 0 1rem;
    }
    @media (max-width: 500px) {
      border-radius: 0.5rem;
      padding: 0 0.8rem;
    }
    h3 {
      color: #fff;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:nth-child(2) {
        margin-left: 2.5rem;
        @media (max-width: 500px) {
          margin-left: 4rem;
        }
        @media (max-width: 450px) {
          margin-left: 2rem;
        }
        @media (max-width: 380px) {
          margin-left: 1.7rem;
        }
      }
      @media (max-width: 500px) {
        font-size: 0.85rem;
      }
    }
  }

  &__items {
    margin-top: 3rem;
    @media (max-width: 500px) {
      margin-top: 2rem;
    }
    // min-width: 350px;
    // overflow: auto;

    &__loading {
      display: flex;
      align-items: center;
      margin: 4rem auto;
      margin-bottom: 6rem;
      width: fit-content;

      img {
        width: 30px;
        @media (max-width: 500px) {
          width: 25px;
        }
      }

      p {
        color: #3e4347;
        text-align: center;
        font-family: Aeonik;
        font-size: 1.1rem;
        margin-left: 0.5rem;
        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }
    }
  }

  &__notFound {
    margin: 3rem 0;
    &__logo {
      display: flex;
      align-items: center;
      margin: auto;
      width: fit-content;
      img {
        width: 40px;
        @media (max-width: 600px) {
          width: 30px;
        }
      }
      h2 {
        color: #1e1e1e;
        text-align: center;
        font-family: Aeonik;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 0.5rem;
        b {
          color: #262cbe;
        }
        @media (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }

    p {
      color: #3e4347;
      text-align: center;
      font-family: Aeonik;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 450px;
      margin: auto;
      @media (max-width: 600px) {
        font-size: .9rem;
        font-weight: 400;
        max-width: 350px;
      }
    }
  }
}
