.home {
  //   h1 {
  //     font-family: Aeonik;
  //     font-weight: 300;
  //     font-size: 2rem;
  //   }

  &__intro {
    padding: 2rem 4rem;
    @media (max-width: 750px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }
    &__heading {
      color: #262cbe;
      text-align: center;
      font-family: Aeonik;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 900px;
      margin: auto;
      @media (max-width: 850px) {
        font-size: 3.4rem;
      }
      @media (max-width: 750px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2.5rem;
      }
      @media (max-width: 500px) {
        font-size: 2rem;
      }
      @media (max-width: 400px) {
        font-size: 1.7rem;
      }
    }

    &__nations {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      @media (max-width: 500px) {
        margin-top: 0.5rem;
      }

      img {
        margin: 0 0.4rem;
        width: 40px;
        @media (max-width: 750px) {
          width: 30px;
          margin: 0 0.2rem;
        }
        @media (max-width: 400px) {
          width: 25px;
          margin: 0 0.1rem;
        }
      }

      &__more {
        border-radius: 2.5rem;
        background: rgba(217, 217, 217, 0.26);
        padding: 0.5rem 1rem;
        p {
          font-size: 0.875rem;
          color: #000;
        }
      }
    }
    &__desc {
      text-align: center;
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 900px;
      margin: auto;
      @media (max-width: 750px) {
        font-size: 1rem;
        max-width: 600px;
      }

      @media (max-width: 400px) {
        font-size: 0.9rem;
      }
    }
  }

  &__rates {
    padding: 0 2rem;
    @media (max-width: 600px) {
      padding: 0 1rem;
    }
  }

  &__pagination {
    margin: auto;
    margin-bottom: 5rem;
    @media (max-width: 600px) {
      margin-bottom: 3rem;
    }
  }


  &__disclaimer {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 2rem;
    padding-top: 4rem;
   
    p {
      font-size: 1rem;
      text-align: justify;
      color: #808080;
      font-family: Aeonik;
      font-size: .95rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0rem;
      @media (max-width: 1032px) {
        margin: 0 2rem;
      }
      @media (max-width: 600px) {
        margin: 0 1rem;
        font-size: .8rem;
      }
    }
  }
}
