.providers {
  //   h1 {
  //     font-family: Aeonik;
  //     font-weight: 300;
  //     font-size: 2rem;
  //   }

  &__intro {
    padding: 2rem 4rem;
    @media (max-width: 750px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }
    &__heading {
      color: #262cbe;
      text-align: center;
      font-family: Aeonik;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 900px;
      margin: auto;
      @media (max-width: 850px) {
        font-size: 3.4rem;
      }
      @media (max-width: 750px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2.5rem;
      }
      @media (max-width: 500px) {
        font-size: 2rem;
      }
      @media (max-width: 400px) {
        font-size: 1.7rem;
      }
    }

    &__desc {
      text-align: center;
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 600px;
      margin: auto;
      margin-top: 0.5rem;
      @media (max-width: 750px) {
        font-size: 1rem;
        max-width: 600px;
      }

      @media (max-width: 400px) {
        font-size: 0.9rem;
      }
    }
  }

  &__providers {
    width: 100%;
    max-width: 700px;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    @media (max-width: 750px) {
      padding: 0 2rem;
    }

    @media (max-width: 600px) {
      padding: 0 1rem;
    }
    @media (max-width: 500px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
    }
  }

  &__provider {
    width: 100%;
    height: 90px;
    border-radius: 1.5rem;
    border: 1px solid var(--neutral-colors-400, #d6dce5);
    box-shadow: 0px 2px 20px 0px rgba(183, 183, 183, 0.08),
      0px 20px 40px 0px rgba(183, 183, 183, 0.08);
    position: relative;
    display: grid;
    place-content: center;
    padding: 0.5rem 1.5rem;
    @media (max-width: 500px) {
      height: 80px;
    }
    img {
      height: 50px;
      width: 100%;
      max-width: 130px;
      object-fit: contain;
      @media (max-width: 500px) {
        height: 40px;
      }
    }
  }

  &__disclaimer {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 2rem;
    padding-top: 4rem;
   
    p {
      font-size: 1rem;
      text-align: justify;
      color: #808080;
      font-family: Aeonik;
      font-size: .95rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0rem;
      @media (max-width: 1032px) {
        margin: 0 2rem;
      }
      @media (max-width: 600px) {
        margin: 0 1rem;
        font-size: .8rem;
      }
    }
  }
}
