.rates {
  max-width: 1000px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 4rem;
  @media (max-width: 600px) {
    margin-bottom: 2rem;
  }
  &__heading {
    border-radius: 0.8rem;
    background: #262cbe;
    display: grid;
    // grid-template-columns: 1fr 1fr 150px;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    color: #fff;
    padding: 0 0rem;
    @media (max-width: 750px) {
      height: 60px;
      padding: 0 0rem;
    }
    @media (max-width: 500px) {
      border-radius: 0.5rem;
      padding: 0 0rem;
      // grid-template-columns: 1fr 1fr 60px;
    }

    h3 {
      color: #fff;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:first-child {
        //width: 200px;
        text-align: left;
        padding-left: 0.5rem;
      }
      &:last-child {
        //width: 100px;
        //text-align: left;
        //margin-left: auto;

        @media (max-width: 500px) {
        }

        span {
          display: block;
          width: 100px;
          margin-left: auto;
          // background: teal;
          text-align: left;
          @media (max-width: 500px) {
            width: 60px;
            text-align: right;
            padding-right: 0.5rem;
          }
        }
      }
      &:nth-child(2) {
        text-align: center;
        // background: red;
        transform: translateX(-2px);
      }
      &:first-child,
      &:last-child {
        width: 200px;
        //background: pink;
        @media (max-width: 750px) {
          width: 120px;
        }
        @media (max-width: 420px) {
          width: 100px;
        }
        @media (max-width: 340px) {
          width: 80px;
        }
      }
      @media (max-width: 500px) {
        font-size: 0.85rem;
      }
    }
  }

  &__items {
    margin-top: 2rem;
    @media (max-width: 500px) {
      margin-top: 1.5rem;
    }

    &__loading {
      display: flex;
      align-items: center;
      margin: 4rem auto;
      margin-bottom: 6rem;
      width: fit-content;

      img {
        width: 30px;
        @media (max-width: 500px) {
          width: 25px;
        }
      }

      p {
        color: #3e4347;
        text-align: center;
        font-family: Aeonik;
        font-size: 1.1rem;
        margin-left: 0.5rem;
        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }
    }
    // min-width: 350px;
    // overflow: auto;
  }
}
