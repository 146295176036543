.conversion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  @media (max-width: 750px) {
    border-bottom: 1px solid #e8e7e7;
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 500px) {
    margin-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  ///background: pink;

  &__platform,
  &__receiverRate {
    width: 200px;
    margin-top: 0rem;
    @media (max-width: 500px) {
      width: fit-content;
    }
  }

  &__receiverRate {
    &__main {
      width: 100px;
      margin-left: auto;
      @media (max-width: 500px) {
        width: 70px;
      }
    }
    h4 {
      color: #1e1e1e;
      font-family: Aeonik;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 500px) {
        font-size: 1rem;
        text-align: right;
      }
    }
    h6 {
      color: #0ec120;
      font-family: Aeonik;
      font-size: 0.7125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0.3rem;
      @media (max-width: 500px) {
        font-size: 0.6rem;
        text-align: right;
      }
    }
  }

  &__rate {
    p {
      color: #1e1e1e;
      text-align: center;
      font-family: Aeonik;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 500px) {
        font-size: 0.9rem;
      }
    }
  }
}
